import AOS from 'aos';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';
import VideoBackgrounds from 'youtube-background';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);

window.Swiper = Swiper;
window.VideoBackgrounds = VideoBackgrounds;
// JavaScript
$(document).ready( function () {
  // Mac
  if (navigator.userAgent.indexOf('Mac OS X') != -1) {
    $('body').addClass('is-Mac');
  }

  AOS.init({
    disable: 'phone',
  });

  $('[data-vbg]').youtube_background({
    // 'play-button': true
    'inline-styles': 'cover'
  });

  if ('[data-page=home]') {
    $('[data-routes-name=pages-index]').addClass('ani-start');
    setTimeout(() => {
      // $("html,body").scrollTop(0);
      $('.index-banner-ani').addClass('ani-start');
      $('[data-routes-name=pages-index]').addClass('ani-over');
    }, 2700);
    setTimeout(() => {
      $('.index-banner-ship').addClass('ani-start');
      $('.index-banner-ani').addClass('ani-over');
    }, 3200);
    setTimeout(() => {
      $('.index-banner-content').addClass('ani-start');
      $('.index-banner-down').addClass('ani-start');
    }, 4000);
  }
  $('[data-mobile-menu]').on('click', function() {
    let title = $(this).find('.menu-text').text();
    let content = $(this).find('.menu-detail').html();
    $('.mobile-menu-detail .detail-title').text(title);
    $('.mobile-menu-detail .mobile-menu-detail-body').html(content);
    $('.mobile-menu-detail').addClass('is-active');
  });
  $('.mobile-menu-detail .close').on('click', function() {
    $('.mobile-menu-detail-inner').addClass('is-hide');
    setTimeout(() => {
      $('.mobile-menu-detail').removeClass('is-active');
      $('.mobile-menu-detail-inner').removeClass('is-hide');
    }, 300);

  });
  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  if ($(window).scrollTop() > 100) {
    $('.header').addClass("min-header");
  } else {
    $('.header').removeClass("min-header");
  }

  // transparent header
  $(window).on('scroll', function () {
    var scrollH = 100;
    if($(window).width() <= 800) {
      var scrollH = 10;
    }
    if ($(window).scrollTop() > scrollH) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  // header bg
  if ($('[data-header=pad_bg_transparent]').height() >= 1) {
    $('.header').addClass("pad-bg-transparent");
  }

  // inner page header
  if ($('[data-header=pad_back]').height() >= 1) {
    $('.header').addClass("pad-back");
  }

  //article mobile search
  $("#banner_search_btn").on('click', function() {
    if ($(this).hasClass('is-show')) {
      $('#banner_search_btn, .banner-search-form').removeClass('is-show');
      $('#banner_search_btn, .banner-search-form').attr('data-visible', 'false');
    }else {
      $('#banner_search_btn, .banner-search-form').addClass('is-show');
      $('#banner_search_btn, .banner-search-form').attr('data-visible', 'true');
    }
  });

  // keyword search
  if ($('.keyword').length > 0) {
    if ($('.keyword').find('input').val() == '') {
      $('.form-reset').removeClass('is-show');
    } else {
      $('.form-reset').addClass('is-show');
    }
  }
  $('.keyword').find('input').on('change input', function() {
    if ($(this).val() == '') {
      $('.form-reset').removeClass('is-show');
    }else {
      $('.form-reset').addClass('is-show');
    }
  });

  // table responsive
  if ($('.custom-editor table').length >= 1) {
    $('.custom-editor table').wrap('<div class="table-responsive"/></div>');
  }

  // custom-editor iframe responsive
  if ($('.custom-editor iframe').length >= 1) {
    $('.custom-editor iframe').wrap('<div class="iframe-outer"></div>');
  }

  // tab
  $('.tab[data-tab]').on('click', function () {
    var tabPanes = $(this).data('tabs');
    var ID = $(this).data('tab');
    $(this).addClass('is-active').siblings().removeClass('is-active');
    $("[data-tab-panes=" + tabPanes + "]").find("[data-tab-pane=" + ID + "]").addClass('is-active').siblings().removeClass('is-active');
  });

  var galleryThumbs = new Swiper("#galleryThumbs", {
    slidesPerView: 5,
    spaceBetween: 3,
    freeMode: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
    breakpoints: {
      900: {
        slidesPerView: 8,
        spaceBetween: 5,
      },
      1360: {
        slidesPerView: 6,
        spaceBetween: 6,
      },
    }
  });
  var galleryImages = new Swiper('#galleryImages', {
    slidesPerView: 1,
    thumbs: {
      swiper: galleryThumbs,
    },
  });

  //race year
  if ($('.race-years').length >= 1) {
    var year_width = $('.race-years-outer').width();
    var year_inner_width = $('.race-years-inner').outerWidth();
    var year_item = $('.race-year.is-active').width();
    if ($('.race-year').hasClass('is-active')) {
      var item_left_size = $('.race-year.is-active').offset().left;
      if (item_left_size > (year_width / 2 - year_item/2)) {
        var scrollTo = item_left_size - (year_width / 2) + (year_item/2);
        $('.race-years-outer').scrollLeft(scrollTo);
      };
    }
    if (year_inner_width > year_width ) {
      $('.race-btn').addClass('show');
    }
    $('.race-btn-next').on('click', function() {
      $('.race-years-outer').animate({ scrollLeft: '+=' + year_item }, 300);
    });
    $('.race-btn-prev').on('click', function() {
      $('.race-years-outer').animate({ scrollLeft: '-=' + year_item }, 300);
    });
    year_btn_disabled(year_width, year_inner_width);
    $('.race-years-outer').scroll(function() {
      year_btn_disabled(year_width, year_inner_width);
    });
  }
  function year_btn_disabled(width, inner_width) {
    var year_scroll_offset = $('.race-years-inner').offset().left;
    if (-year_scroll_offset > 20) {
      $('.race-btn-prev').removeClass('btn-disabled');
    }else {
      $('.race-btn-prev').addClass('btn-disabled');
    }
    if (year_scroll_offset < width - inner_width + 30) {
      $('.race-btn-next').addClass('btn-disabled');
    }else {
      $('.race-btn-next').removeClass('btn-disabled');
    }
  }
  //race images
  var raceThumbs = new Swiper("#raceThumbs", {
    slidesPerView: 5,
    spaceBetween: 3,
    freeMode: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
    breakpoints: {
      900: {
        slidesPerView: 8,
        spaceBetween: 5,
      },
      1360: {
        slidesPerView: 7,
        spaceBetween: 6,
      },
    }
  });
  var raceImages = new Swiper('#raceImages', {
    slidesPerView: 1,
    pagination: {
      el: '.gallery-num-pagination',
      type: 'fraction',
      formatFractionCurrent: function (number) {
        if (number <= 9) {
          return '0' + number;
        }else {
          return number;
        }
      },
      formatFractionTotal: function (number) {
        if (number <= 9) {
          return '0' + number;
        }else {
          return number;
        }
      },
      renderFraction: function (currentClass, totalClass) {
        return '<span class="pagination-item ' + currentClass + '"></span>' + '<span class="pagination-item ' + totalClass + '"></span>';
      }
    },
    thumbs: {
      swiper: raceThumbs,
    },
  });

  var raceMore = new Swiper('#raceMore', {
    slidesPerView: 2.5,
    breakpoints: {
      500: {
        slidesPerView: 3,
      },
      1300: {
        slidesPerView: 4,
      },
    },
  });

  var yachtImages = new Swiper('#yachtImages', {
    slidesPerView: 1,
    loop: true,
    autoplay: true,
    speed: 1000,
    effect: "fade",
    pagination: {
      el: '.gallery-num-pagination',
      type: 'fraction',
      formatFractionCurrent: function (number) {
        if (number <= 9) {
          return '0' + number;
        } else {
          return number;
        }
      },
      formatFractionTotal: function (number) {
        if (number <= 9) {
          return '0' + number;
        } else {
          return number;
        }
      },
      renderFraction: function (currentClass, totalClass) {
        return '<span class="pagination-item ' + currentClass + '"></span>' + '<span class="pagination-item ' + totalClass + '"></span>';
      }
    },
  });

  var coaches = new Swiper('#coaches_items', {
    slidesPerView: 1.3,
    autoResize: false,
    spaceBetween: 20,
    breakpoints: {
      0: {
        slidesOffsetAfter: 30,
        slidesOffsetBefore: 30,
      },
      500: {
        slidesPerView: 2.5,
        spaceBetween: 20,
        slidesOffsetBefore: 30,
        slidesOffsetAfter: 30,
      },
      900: {
        slidesPerView: 3.5,
        spaceBetween: 30,
      },
      1300: {
        slidesPerView: 4.5,
        spaceBetween: 30,
      },
      2001: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
  });

  $('.asa-coaches-item').on('click', function() {
    var coachesContent = $(this).html();
    Swal.fire({
      width: 800,
      showCloseButton: true,
      showConfirmButton: false,
      html: "<div class='asa-coaches-full'>" + coachesContent + "</div>",
      customClass: {
        container: 'asa-coaches-popup',
      }
    });
  });
});
