import 'sweetalert2/dist/sweetalert2.min.css';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import 'public/style.scss'
import Cookies from 'js-cookie';
import 'core-js';
import 'admin/plugins/flatpickr';
import 'public/main'
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import ahoy from "ahoy.js"
import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;
window.Swal = Swal;
